<template>
	<div :style="{height:screenHeigt+'px',['background-color']:'#242424',color:'white'}">
		<div style="font-size: 75px; text-align: center; letter-spacing: 4px;padding-top: 50px;">
			距离高考有且仅有<label style="color:chartreuse;">{{days}}天{{hours}}时{{minutes}}分</label><label
				style="color: red;">{{seconds}}秒</label>
		</div>
		<div style="font-size: 75px; text-align: center;margin-top: 20px; letter-spacing: 4px; color:chocolate;">
			高三2班，我要雄起！！！
		</div>
		<div
			style="display: flex;flex-direction: row;animation: myfirst 146s infinite;position:relative;animation-timing-function: linear;">
			<div v-for="(item,index) in student_data" :key="index">
				<div style="text-align: center;font-size: 25px;">{{item.name}}</div>
				<div v-if="item.student_profile==null"></div>
				<div v-else style="text-align: center;font-size: 25px;color: red;"> ({{item.student_profile.ideal_university}})</div>
				<img :src="'https://digital-school-education.oss-cn-shanghai.aliyuncs.com/public/static/student_image/'+item.image_src"
					width="200px" />
			</div>
		</div>
		<div>
			<audio controls autoplay>
				<source src="https://www.ysdeyu.cn/dt6/public/static/bk.mp3" type="audio/mpeg" />
				你的浏览器不支持
			</audio>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'DtLogin',
		data() {
			return {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
				student_data: [],
			}
		},
		mounted() {
			this.countdown()
		},
		created() {
			this.service.get("/admin/admin/class2").then(respon => {
				// console.log(respon.data)
				this.student_data = respon.data
			}, err => {
				alert(err.message)
			})
		}, // 
		methods: {
			countdown() {
				setInterval(() => {
					var currentTime = new Date().getTime();
					var timeLeft = new Date("2025-06-07 00:00:00") - currentTime;
					// 计算时间
					this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
					timeLeft = timeLeft - this.days * (1000 * 60 * 60 * 24);
					this.hours = Math.floor(timeLeft / (1000 * 60 * 60));
					timeLeft = timeLeft - this.hours * (1000 * 60 * 60);
					this.minutes = Math.floor(timeLeft / (1000 * 60));
					timeLeft = timeLeft - this.minutes * (1000 * 60)
					this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
				}, 1000)
			}
		}

	}
</script>

<style>
	@keyframes myfirst {
		0% {
			left: 1600px;
			top: 0px;
		}

		100% {
			left: -12400px;
			top: 0px;
		}
	}
</style>